/* ==========================================================================
   GRID
   ========================================================================== */

// Use this class whenever you want a grid layout
.container {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-auto-flow: column;
}

// This does a 20%-80% two col grid
.container-20-80 {
  @extend .container;
  grid-auto-columns: 20% 80%;
  grid-auto-rows: 150px;
}

// Use this class when you want to display a bunch of images in a grid
.container-imgs {
  @extend .container;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 250px;
  grid-auto-flow: row;
}

// Define all the screen size dependent CSS for .container-imgs
@media screen and (max-width: 768px) {
  .container-imgs {
    grid-template-columns: 1fr 1fr;;
    grid-auto-rows: 212px;
  }
}

@media screen and (max-width: 480px) {
  .container-imgs {
    grid-template-columns: 1fr;
    grid-auto-rows: 175px;
  }
}

/************************************************************************
 * Things that are put inside containers
*************************************************************************/
.item {
  align-self: center;
}

// CSS additive properties to .item
.left {
  grid-column: 1;
}

.right {
  grid-column: auto;
}

.item-img {
  grid-column: auto; // Don't care about order, just fill up the columns
  justify-self: center; // Center horizontally
}

.item img {
  height: inherit; // Inherit the height style of item
  object-fit: contain; // Maintain aspect-ratio, but stay within container
}

// For all img tags inside .item-img
.item-img img {
  height: 100%; // Don't exceed size of container
  width: 100%; // Don't exceed size of container
  object-fit: cover; // Maintain aspect-ratio, but take up entire container
}
